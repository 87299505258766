<script>
export default {
  name: "AppFooter",
  i18n: {
    messages: {
      en: {
        company: "Company",
        footerText: {
          title: "Risk Warnings",
          text: [
            "Trading financial assets such as stocks, futures, cryptocurrencies and currency pairs offers great opportunities for profit, but also carries high risks. It is important to study the characteristics of each financial instrument in detail before starting trading. Make sure you fully understand all the risks associated with trading in order to minimize potential losses.",
            "It is important to remember that successful trades in the past do not guarantee similar results in the future. Trading in financial markets is always associated with risks. We recommend diversifying your investment portfolio to reduce these risks and closely monitoring economic and geopolitical factors that may affect market movements.",
          ],
        },
      },
      ru: {
        company: "Компания",
        footerText: {
          title: "Предупреждения о рисках",
          text: [
            "Торговля финансовыми активами, такими как акции, фьючерсы, криптовалюты и валютные пары, открывает широкие возможности для получения прибыли, но также сопровождается высокими рисками. Перед началом торговой деятельности важно детально изучить характеристики каждого финансового инструмента. Убедитесь, что вы полностью понимаете все риски, связанные с торговлей, чтобы минимизировать потенциальные убытки.",
            "Важно помнить, что успешные сделки в прошлом не гарантируют аналогичных результатов в будущем. Торговля на финансовых рынках всегда сопряжена с рисками. Мы рекомендуем диверсифицировать ваш инвестиционный портфель для снижения этих рисков и внимательно следить за экономическими и геополитическими факторами, которые могут влиять на движение рынков.",
          ],
        },
      },
      fr: {
        company: "Compagnie",
        footerText: {
          title: "Avertissements sur les risques",
          text: [
            "Les services et documents d'information et d'analyse publiés sur le site ne constituent pas des instructions d'action. Le trading d'instruments financiers tels que les actions, les indices, les matières premières, les crypto-monnaies et les paires de devises peut générer des bénéfices et est associé à un niveau de risque élevé. Avant de commencer à trader , il est recommandé d'étudier les caractéristiques de chaque instrument. Vous devez évaluer soigneusement votre profil de risque et votre situation financière avant de négocier.",
            "N'oubliez pas que les performances passées ne garantissent pas le succès futur et que le trading implique toujours certains risques. Nous vous recommandons fortement d'allouer votre portefeuille d'investissement de manière à réduire les risques et de prêter attention à l'environnement économique actuel et aux facteurs géopolitiques qui peuvent affecter les marchés financiers.",
          ],
        },
      },
      de: {
        company: "Unternehmen",
        footerText: {
          title: "Risikowarnungen",
          text: [
            "Die auf der Website veröffentlichten Informationen und Analysedienste und Materialien stellen keine Handlungsanweisungen dar. Der Handel mit Finanzinstrumenten wie Aktien, Indizes, Rohstoffen, Kryptowährungen und Währungspaaren kann Gewinne bringen und ist mit einem hohen Risiko verbunden. Bevor Sie mit dem Handel beginnen Es wird empfohlen, die Merkmale jedes Instruments zu studieren. Sie sollten Ihr Risikoprofil und Ihre finanzielle Situation sorgfältig bewerten, bevor Sie handeln.",
            "Denken Sie daran, dass die Wertentwicklung in der Vergangenheit keine Garantie für zukünftigen Erfolg ist und dass der Handel immer mit gewissen Risiken verbunden ist. Wir empfehlen Ihnen dringend, Ihr Anlageportfolio so zu verteilen, dass das Risiko reduziert wird, und auf das aktuelle wirtschaftliche Umfeld und geopolitische Faktoren zu achten, die sich auf die Finanzmärkte auswirken können.",
          ],
        },
      },
    },
  },
  computed: {
    getYear() {
      return new Date().getFullYear();
    },
  },
};
</script>

<template>
  <footer class="black-bg footer section-80">
    <div data-aos="fade-up" class="container">
      <div class="row row-gap-20">
        <div class="col-sm-6 col-xl-3">
          <div class="footer-block__content">
            <div>
              <router-link :to="$localePath({ name: 'Home' })" class="footer__logo">
                <img :src="require('@/assets/image/logo-white.svg')" alt="logo" />
              </router-link>

              <div class="footer__contacts">
                <p>
                  <a href="mailto:support@seisay.com" class="footer__link"> E-mail </a>
                </p>
                <p>
                  <a href="tg://resolve?domain=seisay_bot" class="footer__link">Telegram</a>
                </p>
              </div>
            </div>
            <p class="d-none d-xl-block text-opacity-06">© {{ getYear }} All rights reserved.</p>
          </div>
        </div>
        <div class="col-sm-6 col-xl-3">
          <div class="footer-block__content">
            <div>
              <div class="h4 footer__title">{{ $t("company") }}</div>
              <ul class="footer__links-list">
                <li>
                  <router-link :to="$localePath({ name: 'Market' })" class="footer__link">
                    {{ $t("menu.markets") }}
                  </router-link>
                </li>
                <li>
                  <router-link :to="$localePath({ name: 'Platform' })" class="footer__link">
                    {{ $t("menu.platform") }}
                  </router-link>
                </li>
                <li>
                  <router-link :to="$localePath({ name: 'Trade' })" class="footer__link">
                    {{ $t("menu.trade") }}
                  </router-link>
                </li>
                <li>
                  <router-link :to="$localePath({ name: 'Strategies' })" class="footer__link">
                    {{ $t("menu.strategies") }}
                  </router-link>
                </li>
                <li>
                  <router-link :to="$localePath({ name: 'Tools' })" class="footer__link">
                    {{ $t("menu.tools") }}
                  </router-link>
                </li>
                <li>
                  <router-link :to="$localePath({ name: 'About' })" class="footer__link">
                    {{ $t("menu.about") }}
                  </router-link>
                </li>
                <li>
                  <router-link :to="$localePath({ name: 'Contacts' })" class="footer__link">
                    {{ $t("menu.contacts") }}
                  </router-link>
                </li>
              </ul>
            </div>
            <ul class="d-none d-xl-flex footer__links-list">
              <li>
                <router-link :to="$localePath({ name: 'Privacy' })" class="footer__link">
                  {{ $t("legalInfo") }}
                </router-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-xl-6">
          <div class="h4 footer__title">{{ $t("footerText.title") }}</div>
          <div class="footer__text-block text-opacity-06">
            <p v-for="text in $t('footerText.text')" :key="text">{{ text }}</p>
          </div>
        </div>
      </div>
      <div class="d-xl-none">
        <div class="footer__divider"></div>
        <div class="row row-gap-20 flex-sm-row-reverse">
          <div class="col-sm-6">
            <ul class="footer__links-list">
              <li>
                <router-link :to="$localePath({ name: 'Privacy' })" class="footer__link">
                  {{ $t("legalInfo") }}
                </router-link>
              </li>
            </ul>
          </div>
          <div class="col-sm-6">
            <p class="text-opacity-06">© {{ getYear }} All rights reserved.</p>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<style lang="scss" scoped>
.footer {
  font-size: 16px;
  color: $white;
}
.footer__logo {
  display: inline-flex;
  max-width: 141px;
  margin-bottom: 24px;
}
.footer__title {
  text-transform: uppercase;
  margin-bottom: 16px;
  color: $white;
}
.footer__text-block {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.footer-block__content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  gap: 16px;
}
.footer__link {
  color: $white;

  &:hover {
    color: $blue;
  }
}
.footer__links-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.footer__contacts {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.footer__divider {
  border-top: 1px solid rgba($white, 0.3);
  margin-top: 40px;
  margin-bottom: 40px;
}

@include media-breakpoint-down(xl) {
  .footer__links-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

@include media-breakpoint-down(md) {
  .footer__divider {
    margin-top: 25px;
    margin-bottom: 25px;
  }
}
</style>
